import React, { useEffect, useState } from 'react'
import axios from "axios";
import Config from "../../util/config";
import { Link, useLocation } from 'react-router-dom';

const SearchArea = () => {

    const [articles, setArticles] = useState([]);
    const [article, setArticle] = useState({
        id: "",
        title: "",
        module_id: ""
    });
    const [flag, setFlag] = useState(false);

    const location = useLocation();
    console.log(location.pathname);

    const getAllArticles = () => {
        axios.get(Config.apiserver + "allarticle")
            .then(res => {
                console.log(res.data.data);
                setArticles(res.data.data);
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getAllArticles()
    }, []);

    const handleSearch = (e) => {
        setFlag(true);
        // console.log(e);
        let value = e?.target?.value;
        // console.log(value);
        const searchData = [];
        if (value === '') {
            setFlag(false);
        }

        articles.map((item, i) => {
            if (item.title.toLowerCase().includes(value.toLowerCase())) {
                searchData.push({
                    id: item.id,
                    title: item.title,
                    module_id: item.module_id
                });
            }
        })
        setArticle(searchData)

    }

    return (
        <>
            <section className="slice pt-5 pb-5 bg-dark">
                <img src="/images/searchbg.jpg" alt="CreativeItSoft" className="img-as-bg blend-mode--multiply" />
                <div data-offset-top="#navbar-main">
                    <div className="container d-flex align-items-center text-center text-lg-left">
                        <div className="col px-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 text-center">
                                    {location.pathname === "/" ? (
                                        <h2 className="h1 text-white mb-2">Knowledge Center</h2>
                                    ) : ""}

                                    {/*<form className="mt-2">*/}
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search" aria-label="Example text with button addon" aria-describedby="button-addon1" onChange={handleSearch} />
                                        <div className="input-group-append">
                                            <button className="btn btn-warning" type="button" id="button-addon1">Search</button>
                                        </div>
                                    </div>
                                    {
                                        flag === true && (
                                            <ul className="list-group">
                                                {
                                                    article.map((item, index) =>
                                                        <li className="list-group-item font-weight-bold px-4 " key={index}>
                                                            {location.pathname === "/module/details" ? (
                                                                <a href={`/module/details?mdl_id=${item.module_id}&article_id=${item.id}`}>{item.title}</a>
                                                            ) : (
                                                                <Link to={`/module/details?mdl_id=${item.module_id}&article_id=${item.id}`} >{item.title}</Link>
                                                            )}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        )
                                    }
                                    {/*</form>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SearchArea
