import React from 'react'
import { Link } from 'react-router-dom'

const Module = ({ link, icon, title }) => {
    return (
        <>
            <div className="col-lg-4 mb-3 mb-lg-5">
                <Link className="card card-frame h-100" to={`/module/${link}`}>
                    <div className="card-body px-2 py-0">
                        <div className="media d-block d-sm-flex">
                            {/* <figure className="w-100 max-w-8rem mb-2 mb-sm-0 mr-sm-4"> */}
                                <i className={`icon ${icon}`} style={{ fontSize: "45px", margin: "0 15px" }} />
                            {/* </figure> */}
                            {/* <div className="media-body"> */}
                                <h5 style={{margin: "0"}}>{title}</h5>
                                {/* <p className="font-size-1 text-body">{subtitle}</p> */}
                            {/* </div> */}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Module
