module.exports = {
	footerText: 'All rights reserved by - Yotta SME',

	apiserver: 'https://supportapi.yottasme.com/api/',


	// apiserver: 'https://fmsapi.yottaerp.com/api/',

	profile_pic_url: 'https://supportapi.yottasme.com/public/upload/profile/',

	// detailsImgURL: 'https://fmsapi.yottaerp.com/public/upload/support_article/',
	detailsImgURL: 'https://supportapi.yottasme.com/public/upload/support_article/',
	
	logourl : '/images/yotta-logo.svg',
	print_logourl : '/images/yotta-logo.svg',
	company_name : 'Yotta SME',
	address : 'Dhaka, Bangladesh',
	city : 'Dhaka',
	state : 'Dhaka',
	zipcode : '1207',
	phone : "",
	email : "info@yottasme.com",
	website : "https://yottasme.com"
}