import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <>
            <section>
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-between min-vh-100">
                        <div className="col-12 col-md-6 col-xl-7 order-md-2"><img alt="CreativeItSoft" src="https://yottasme.com/public/assets/img/svg/illustrations/illustration-13.svg" className="img-fluid" /></div>
                        <div className="col-12 col-md-6 col-xl-5 order-md-1 text-center text-md-left"><h6 className="display-1 mb-3 font-weight-600 text-warning">Ooops!</h6>
                            <p className="lead text-lg mb-5">The page you are looking for could not be found.</p><Link to="/" className="btn btn-dark btn-icon hover-translate-y-n3"><span className="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></span> <span className="btn-inner--text">Return home</span></Link></div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NotFound
