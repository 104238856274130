import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import SearchArea from '../components/SearchArea/SearchArea';
import config from '../util/config';

const Modules = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [moduleName, setModuleName] = useState([]);
    const [loading, setLoading] = useState(false)

    const getModuleData = () => {
        setLoading(true)
        axios.get(config.apiserver + "modules/" + params.id)
            .then(res => {
                console.log(res.data)
                setData(res.data.data);
                setModuleName(res.data.module_name)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    useEffect(() => {
        // console.log(data);
        getModuleData()
    }, []);
    return (
        loading ? <Loading /> :
            <>
                {/* <SearchArea /> */}
                <div className='container py-5'>

                    <Link to={`/`} className="btn btn-sm btn-primary btn-icon d-lg-inline-flex mb-4"><i className="icon icon-arrow-left" style={{ margin: "2px 10px 0 0" }} />Back To Home</Link>

                    <h2 className='font-weight-bold mt-3'>{moduleName}</h2>

                    <div className="accordion mt-4" id="accordionExample" >
                        {data.map((item, index) =>
                            <div className="card" key={index} style={{}}>
                                <div className="card-header pt-0" id={`headingThree_${index}`} >
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed font-weight-bold" type="button" data-toggle="collapse" data-target={`#collapseThree_${index}`} aria-expanded="false" aria-controls={`collapseThree_${index}`} style={{ fontSize: "18px" }}>
                                            {item.sub_module_name}
                                        </button>
                                    </h2>
                                </div>
                                <div id={`collapseThree_${index}`} className="collapse" aria-labelledby={`headingThree_${index}`} data-parent="#accordionExample">
                                    <div className="card-body ml-3">
                                        {
                                            item?.articles?.length > 0 ?
                                                item?.articles?.map((article, i) =>
                                                    <Link to={`/module/details?mdl_id=${article.module_id}&article_id=${article.id}`} key={i}>
                                                        <p className="ml-5 font-weight-bold">- {article.title}</p>
                                                    </Link>

                                                )
                                                : <p className="ml-5 font-weight-bold" style={{ color: "#333" }}>No Data Found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                </div>
            </>

    )
}

export default Modules