// import React, {useEffect, useState} from 'react'
// import Breadcrumb from '../components/Docs/Breadcrumb'
// import DocContent from '../components/Docs/DocContent'
// import axios from "axios";
// import Config from "../util/config";
// import {Link, useParams} from "react-router-dom";

// const Details = () => {
//     const params = useParams();
//     const [data, setData] = useState([]);
//     const [id, setID] = useState({});

//     const getModuleData = () => {
//         axios.get(Config.apiserver+"modules/"+ params.id)
//             .then(res => {
//                 console.log(res.data.data)
//                 setData(res.data.data);
//             })
//             .catch(error => console.log(error))
//     }

//     useEffect(() => {
//         // console.log(data);
//         getModuleData()
//     }, []);

//     const getArticleData = (module_id,article_id) => {
//         // console.log(module_id, article_id)
//         setID({
//             mdl_id: module_id,
//             article_id: article_id
//         })
//     }

//     useEffect(() => {
//         getArticleData(params.id, 1)
//     }, []);

//     return (
//         <>
//             <Breadcrumb />
//             <div className="container py-4 py-lg-5">
//                 <div className="row">

//                     <div className="col-12 col-lg-3">
//                         <Link to={`/`} className="btn btn-sm btn-primary btn-icon d-lg-inline-flex"><i className="icon icon-arrow-left" style={{ margin: "2px 10px 0 0"}} />Back To Home</Link>
//                         <div className="docs-sidebar pr-5 position-lg-sticky top-lg-6">
//                             <div className="d-lg-block collapse" id="docsSidenavCollapse">
//                                 {data.map((item, index) =>
//                                         <div key={index}>
//                                             <h6 className="mt-4">{ item.sub_module_name }</h6>
//                                             <ul className="nav flex-column">
//                                                 {
//                                                     item.articles.map((article, i) =>
//                                                         <li className="nav-item" key={i}>
//                                                             {/*<Link to={action.act_id} class="nav-link">{action.act_name}</Link>*/}
//                                                             <button
//                                                                 className="btn btn-secondary btn-sm"
//                                                                 style={{ background:"#fff", border:"#fff", padding:"0 0 0 10px", color:"#4a5568" }}
//                                                                 onClick={() => getArticleData(item.module_id, article.id)}
//                                                             >
//                                                                 {article.title}
//                                                             </button>
//                                                         </li>
//                                                     )
//                                                 }

//                                             </ul>
//                                         </div>
//                                     )}

//                             </div>
//                         </div>
//                     </div>
//                     {
//                         Object.keys(id).length !== 0 && (
//                             <DocContent data={id}/>
//                         )
//                     }
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Details


import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Loading from '../components/Loading';
import SearchArea from '../components/SearchArea/SearchArea';
import config from '../util/config';

const Details = () => {

    let marked = window.marked;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mdl_id = urlParams.get('mdl_id');
    const article_id = urlParams.get('article_id');

    console.log(mdl_id )

    const [article, setArticle] = useState({});
    const [details, setDetails] = useState("");
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false)


    const getActionData = () => {
        setLoading(true)
        axios.get(config.apiserver + `getarticledata/?mdl_id=${mdl_id}&&article_id=${article_id}`)
            .then(res => {
                console.log(res)
                setArticle(res?.data?.data);
                setDetails(atob(res?.data?.data?.details));
                setImages(res?.data?.images);
                setLoading(false)
            })
            .catch(error => setLoading(false))
    }

    useEffect(() => {
        getActionData()
    }, []);

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        loading ? <Loading /> :
            <>
                {/* <SearchArea /> */}
                <div className='container'>
                    <div className="col-lg-12 mt-4 mt-lg-0">
                        {/* <Link to={`/module/${mdl_id}`} className="btn btn-sm btn-primary btn-icon d-lg-inline-flex mt-3"><i className="icon icon-arrow-left" style={{ margin: "2px 10px 0 0" }} />Back To Module</Link> */}



                        <article className="docs-content mt-4">
                            <div className='d-flex justify-content-between'>
                                <h2 className='font-weight-bold '>{article?.module_name}</h2>
                                <nav aria-label="breadcrumb" style={{ marginBottom: "0px" }}>
                                    <ol className="breadcrumb justify-content-end mb-3" style={{ border: "none" }}>
                                        <li className="breadcrumb-item font-weight-bold"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item font-weight-bold"><Link to={`/module/${article?.module_id}`}>{article?.module_name}</Link></li>
                                        <li className="breadcrumb-item active " aria-current="page">{article?.submodule_name}</li>
                                    </ol>
                                </nav>
                            </div>
                            {/* <p style={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}>{article?.title}</p> */}

                            <div className="article_title mt-3">
                                <h3 className='font-weight-bold '>{article?.title}</h3>
                            </div>

                            <Slider {...settings}>
                                {images?.length > 0 && (
                                    images?.map((item, index) =>
                                        <div className='single-doc-img' >
                                            <img src={config.detailsImgURL + item?.image} alt="" />
                                        </div>
                                    )
                                )}
                            </Slider>


                            {article?.video_link !== null ? (
                                <div className='video_embed'>
                                    <ReactPlayer url={article?.video_link} width="100%" height="460px" />
                                </div>
                            ) : ""}



                            <div style={{ color: "#333" }} dangerouslySetInnerHTML={{ __html: marked.parse(details) }} />
                        </article>
                    </div>
                </div>
            </>
    )
}

export default Details

