import React from 'react'

import SearchArea from '../components/SearchArea/SearchArea'
import Modules from '../components/Modules/Modules'

const Home = () => {
    return (
        <>
            {/* <SearchArea/> */}
            <Modules/>
        </>
    )
}

export default Home
